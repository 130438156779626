<!-- Preloader -->
<div id="preloader" *ngIf="isSpinnerVisible" [ngClass]="{'light': this.themeConfig.themeType === 'light'}">
  <div class="outer">
    <!-- Google Chrome -->
    <div class="infinityChrome">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <!-- Safari and others -->
    <div class="infinity">
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
    </div>
    <!-- Stuff -->
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="goo-outer">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" ></feGaussianBlur>
          <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"></feColorMatrix>
          <feBlend in="SourceGraphic" in2="goo"></feBlend>
        </filter>
      </defs>
    </svg>
  </div>
</div>
