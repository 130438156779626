<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Serviços</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
        <img src="assets/images/programming.png" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Desenvolvimento Web</h3>
        <p class="mb-0">- Criação de Websites<br>- Criação de Lojas Online<br>- Criação de Aplicações<br>- Landing Pages<br>- Protótipos<br>- Domínio e Alojamento</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
        <img src="assets/images/social-media.png" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Marketing</h3>
        <p class="mb-0">- Gestão de Redes Sociais<br>- Marketing Digital<br>- SEO<br>- Analytics<br>- Publicidade (Ads)<br>- Email Marketing</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
        <img src="assets/images/conversation.png" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Comunicação</h3>
        <p class="mb-0">- Assessoria de Imprensa<br>- Clipping<br>- Press Release<br>- Comunicação de Produto<br>- Conteúdos Editoriais<br>- Planeamento de Meios</p>
      </div>
    </div>
  </div>

  <div class="mt-5 text-center">
    <p class="mb-0">Pretende algum destes serviços? <a href="javascript:" (click)="scrollTo('contact')">Clique aqui</a> e contacte-nos! 👋</p>
  </div>

</div>
