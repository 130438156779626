<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Contacte-nos</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- contact info -->
      <div class="contact-info">
        <h3 class="wow fadeInUp">Precisa de um Orçamento?</h3>
        <p class="wow fadeInUp">Contacte-nos e responderemos o mais breve possível!</p><br>
        <p><span class="fa fa-envelope"></span><a href="mailto:webexperience@gmail.com" target="_blank" style="color: white;">&nbsp;&nbsp;&nbsp;webexperience@gmail.com</a></p>
        <p><span class="fa fa-phone"></span><a target="_blank" style="color: white;">&nbsp;&nbsp;&nbsp;+351 965 523 948</a></p>
      </div>
    </div>

    <div class="col-md-8">
      <!-- Contact Form -->
      <form id="contact-form" target="_blank" class="contact-form mt-6" method="get" action="mailto:webexperience@gmail.com">

        <div class="messages"></div>

        <div class="row">
          <div class="column col-md-6">
            <!-- Name input -->
            <div class="form-group">
              <input type="text" class="form-control" name="InputName" id="InputName" placeholder="Nome" required="required" data-error="Name is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <!-- Email input -->
            <div class="form-group">
              <input type="email" class="form-control" id="cc" name="cc" placeholder="Email" required="required" data-error="Email is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Email input -->
            <div class="form-group">
              <input type="text" class="form-control" id="subject" name="subject" placeholder="Assunto" required="required" data-error="Subject is required.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Message textarea -->
            <div class="form-group">
              <textarea name="body" id="body" class="form-control" rows="5" placeholder="Mensagem" required="required" data-error="Message is required."></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-default">Enviar</button><!-- Send Button -->

      </form>
      <!-- Contact Form end -->
    </div>

  </div>

</div>
