<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeIn">Pricing Plans</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4 pr-md-0 mt-md-4 mt-0">
      <!-- price item -->
      <div class="price-item bg-white rounded shadow-dark text-center">
        <img src="assets/images/price-1.svg" alt="Regular" />
        <h2 class="plan">Basic</h2>
        <p>A Simple option but powerful to manage your business</p>
        <p>Email support</p>
        <h3 class="price"><em>$</em>9<span>Month</span></h3>
        <a href="javascript:" class="btn btn-default">Get Started</a>
      </div>
    </div>

    <div class="col-md-4 px-md-0 my-4 my-md-0">
      <!-- price item recommended-->
      <div class="price-item bg-white rounded shadow-dark text-center best">
        <span class="badge">Recommended</span>
        <img src="assets/images/price-2.svg" alt="Premium" />
        <h2 class="plan">Premium</h2>
        <p>Unlimited product including apps integrations and more features</p>
        <p>Mon-Fri support</p>
        <h3 class="price"><em>$</em>49<span>Month</span></h3>
        <a href="javascript:" class="btn btn-default">Get Started</a>
      </div>
    </div>

    <div class="col-md-4 pl-md-0 mt-md-4 mt-0">
      <!-- price item -->
      <div class="price-item bg-white rounded shadow-dark text-center">
        <img src="assets/images/price-3.svg" alt="Ultimate" />
        <h2 class="plan">Ultimate</h2>
        <p>A wise option for large companies and individuals</p>
        <p>24/7 support</p>
        <h3 class="price"><em>$</em>99<span>Month</span></h3>
        <a href="javascript:" class="btn btn-default">Get Started</a>
      </div>
    </div>

  </div>

</div>
