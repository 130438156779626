<div class="container">
  <div class="row">
    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">2+</em></h3>
          <p class="mb-0">Projetos concluídos</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">140</em></h3>
          <p class="mb-0">Cafés bebidos</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">2+</em></h3>
          <p class="mb-0">Clientes satisfeitos</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-rocket"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">2</em></h3>
          <p class="mb-0">Programadores</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

  </div>
</div>
